import { Link } from "gatsby"
import React, { useState } from "react"
import styled from "styled-components"
import Logo from "./images/logo"

import ShowMenu from "../assets/icons/show-menu.svg"
import HideMenu from "../assets/icons/hide-menu.svg"

const Header = ({ id }) => {

  const [isMobileMenuVisible, setVisibility] = useState(false);

  const showMenu = () => {
    setVisibility(true);
  }

  const hideMenu = () => {
    setVisibility(false);
  }

  return (<Container id={id}>
    <Menu>
      <Link to="/" aria-label="Link to home">
        <LogoContainer>
          <Logo />
        </LogoContainer>
      </Link>
      <MenuItems>
        <MenuItemsContainer>
          <div style={{ padding: 10 }}><MenuItem to="/#header" aria-label="Link to home">Home</MenuItem></div>
          <div style={{ padding: 10 }}><MenuItem to="/#about" aria-label="Link to about section">About</MenuItem></div>
          <div style={{ padding: 10 }}><MenuItem to="/#cases" aria-label="Link to case studies section">Cases</MenuItem></div>
          <div style={{ padding: 10 }}><MenuItem to="/#contact" aria-label="Link to contact section">Contact</MenuItem></div>
          <div style={{ padding: 10 }}><ExternalMenuItem href="https://medium.com/appes" aria-label="Link to Appes blog">Blog</ExternalMenuItem></div>
        </MenuItemsContainer>
      </MenuItems>
      <MobileMenuButton>
        {
          isMobileMenuVisible
            ? <HideMenu style={{ height: 33, width: 33 }} tabIndex={0} onClick={hideMenu} onKeyUp={(e) => e.keyCode === 13 && hideMenu()} aria-label="Hide menu" />
            : <ShowMenu style={{ height: 33, width: 33 }} tabIndex={0} onClick={showMenu} onKeyUp={(e) => e.keyCode === 13 && showMenu()} aria-label="Show menu"/>
        }
      </MobileMenuButton>
      {
        isMobileMenuVisible
          && <MobileMenuContainer>
            <MobileMenu>
              <MenuItem onClick={hideMenu} to="/#header" aria-label="Link to home">Home</MenuItem>
              <MenuItem onClick={hideMenu} to="/#about" aria-label="Link to about section">About</MenuItem>
              <MenuItem onClick={hideMenu} to="/#cases" aria-label="Link to case studies section">Cases</MenuItem>
              <MenuItem onClick={hideMenu} to="/#contact" aria-label="Link to contact section">Contact</MenuItem>
              <ExternalMenuItem onClick={hideMenu} href="https://medium.com/appes" aria-label="Link to Appes blog">Blog</ExternalMenuItem>
            </MobileMenu>
          </MobileMenuContainer>
      }
    </Menu>
  </Container>);
}

const MenuItemsContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const Container = styled.header`
  width: 100vw;
  height: 115px;
  display: flex;
  justify-content: center;
  flex-direction: row;

  @media (max-width: 767px) {
    height: 70px;
  }
`

const LogoContainer = styled.div`
  width: 80px;

  @media (max-width: 767px) {
    width: 50px;
  }
`

const Menu = styled.div`
  border-bottom: 1px solid black;
  width: inherit;
  max-width: 1440px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px 60px 0 60px;

  @media (max-width: 767px) {
    padding: 0 20px;
  }
`

const MenuItems = styled.div`
  @media (max-width: 767px) {
    display: none;
  }

  @media (min-width: 768px) {
    display: visible;
  }
`

const MenuItem = styled(Link)`
  color: black;
  margin: 0 10px;
  font-weight: 900;
  font-size: 20px;
  text-decoration: none;

  :hover {
    text-decoration: underline;
  }

  @media (max-width: 767px) {
    margin: 10px 0 10px 0;
  }
`

const ExternalMenuItem = styled.a`
  color: black;
  margin: 0 10px;
  font-weight: 900;
  font-size: 20px;
  text-decoration: none;

  :hover {
    text-decoration: underline;
  }

  @media (max-width: 767px) {
    margin: 10px 0 10px 0;
  }
`

const MobileMenuButton = styled.div`
  @media (max-width: 767px) {
    display: visible;
  }

  @media (min-width: 768px) {
    display: none;
  }
`

const MobileMenuContainer = styled.div`
  width: 100vw;
  height: 50vh;
  background-color: transparent;

  position: absolute;
  top: 70px;
  left: 0;
  z-index: 100;

  display: flex;
  padding: 0 20px 20px 20px;

  @media (orientation: landscape) {
    height: 80vh;
  }
`

const MobileMenu = styled.div`
  width: 100%;
  height: 100%;
  border-bottom: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;

  background-color: white;
  padding: 20px 40px;

  display: flex;
  flex-direction: column;

  a {
    margin-bottom: 20px;
  }
`

export default Header
