import styled from 'styled-components'

const H1 = styled.h1`
  text-align: left;
  font: 103px/94px Vremena Grotesk;
  letter-spacing: -2.58px;
  color: #000000;
  opacity: 1;

  @media (max-width: 767px) {
    text-align: left;
    font: Bold 59px/54px Vremena Grotesk;
    letter-spacing: -1.48px;
    color: #000000;
    opacity: 1;
  }
`

const H2 = styled.h2`
  text-align: left;
  font: 55px/55px Vremena Grotesk;
  letter-spacing: -1.38px;
  color: #000000;
  opacity: 1;

  @media (max-width: 1336px) {
    text-align: left;
    font: 35px/39px Vremena Grotesk !important;
    letter-spacing: -0.88px;
    color: #000000;
    opacity: 1;
  }
`

const H3 = styled.h3`
  text-align: left;
  font: 29px/35px Vremena Grotesk;
  letter-spacing: -0.72px;
  color: #000000;
  opacity: 1;
  margin-bottom: 10px;

  @media (max-width: 767px) {
    font: 21px/24px Vremena Grotesk !important;
  }
`

const H4 = styled.h4`
  text-align: left;
  font: 21px/24px Vremena Grotesk;
  letter-spacing: -0.52px;
  color: #000000;
  opacity: 1;
`

export { H1, H2, H3, H4 }