import React from "react"
import styled from 'styled-components'
import { Link } from "gatsby"

import Logo from "./images/logo"
import Clutch from "../assets/icons/clutch.svg"
import LinkedIn from "../assets/icons/linkedin.svg"
import Github from "../assets/icons/github.svg"
import { H2, H4 } from "../components/headings"

const FooterComponent = () => (
  <Container>
    <Footer>
      <Top>
        <Link to="/" aria-label="Link to home">
          <LogoContainer>
            <Logo style={{ marginRight: 10 }} />
          </LogoContainer>
        </Link>
        <Address>
          <H4>Get Clarity</H4>
          <a
            aria-label="Send email to sayhi@appes.co"
            href="mailto:sayhi@appes.co?subject=Hello%2C%20Guys%21%20&body=We%20have%20this%20amazing%20idea%20for%20an%20app%2C%20but%20no%20clue%20where%20to%20start.%20Are%20you%20available%20for%20a%20short%20talk%3F"
          >
            sayhi@appes.co
          </a>
          {/* <p>+40 763 753 511 | +40 751 011 725</p> */}
          <p>160 Splaiul Unirii, Bucharest</p>
        </Address>
        <Promise>
          <H2>Meant to evolve</H2>
          <p>
            Increasing digital clarity, one app at a time.
          </p>
        </Promise>
        <Icons>
          <a
            href="https://clutch.co/profile/appes"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="External link to Clutch.com"
          >
            <Clutch style={{ width: 24, height: 24 }} />
          </a>
          <a
            href="https://www.linkedin.com/company/appes-co/about"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="External link to LinkedIn.com"
          >
            <LinkedIn style={{ width: 24, height: 24 }} />
          </a>
          <a
            href="https://github.com/appes-co/"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="External link to Github.com"
          >
            <Github style={{ width: 24, height: 24 }} />
          </a>
        </Icons>
      </Top>
      <Details>
        <p>
          © {new Date().getFullYear()} Appes. All rights reserved. |{" "}
          <Link to="/terms">Terms & Conditions</Link>
        </p>
        <p>
          Designed by{" "}
          <a
            aria-label="External link to Ionutp.com"
            href="https://ionutp.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            ionutp.com
          </a>
        </p>
      </Details>
    </Footer>
  </Container>
);

const Container = styled.footer`
  width: 100vw;
  display: flex;
  justify-content: center;
  flex-direction: row;
`

const LogoContainer = styled.div`
  width: 100px;

  @media (max-width: 1336px) {
    width: 70px;
  }
`

const Footer = styled.div`
  border-top: 1px solid black;
  width: inherit;
  max-width: 1440px;
  height: inherit;
  min-height: 270px;
  display: flex;
  flex-direction: column;
  padding: 0 60px;

  @media (max-width: 1336px) {
    padding: 0;
  }
`

const Top = styled.div`
  min-height: 190px;
  padding: 60px 60px 0 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  @media (max-width: 767px) {
    padding: 30px;  
    flex-direction: column;
    justify-content: space-between;
  }
`

const Details = styled.div`
  background-color: #F2F2F2;
  height: 80px;
  padding: 0 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 767px) {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0 0 0 30px;
  }

  p {
    font-size: 12px;
    margin-bottom: 0;
  }

  a {
    color: black;
  }
`

const Address = styled.div`
  min-width: 210px;

  @media (max-width: 767px) {
    margin: 30px 0;
  }

  h4 {
    font-size: 20px;
    font-weight: 900;
    margin-bottom: 3px;
  }
  
  p, a {
    font-size: 15px;
    margin-bottom: 1px;
    color: black;
  }
`

const Promise = styled.div`
  max-width: 390px;
  margin-top: -7px;

  h2 {
    font-size: 50px;
    font-weight: 900;
    margin-bottom: 0px;
  }

  p {
    font-size: 20px;
    margin-bottom: 1px;
  }

  @media (max-width: 767px) {
    margin-bottom: 30px;
  }
`

const Icons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-width: 90px;
  margin-top: -5px;
`

export default FooterComponent
