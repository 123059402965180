import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import styled, { createGlobalStyle } from 'styled-components'

import VremenaGrotesk from '../assets/fonts/vremenagroteskbold.otf'
import Noah from '../assets/fonts/noah-regular.otf'

import "./layout.css"

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Vremena Grotesk';
    font-style: normal;
    font-weight: bold;
    src: url(${VremenaGrotesk}) format('truetype');
  }

  @font-face {
    font-family: 'Noah';
    font-style: normal;
    font-weight: normal;
    src: url(${Noah}) format('truetype');
  }

  body {
    font-family: 'Noah';
  }

  h1, h2, h3, h4 {
    font-family: 'Vremena Grotesk'
  }
`

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <Container>
      <GlobalStyle />
        <Body>
          <Main>
            {children}
          </Main>
        </Body>
      </Container>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

const Container = styled.div`
  background-color: #fff;
`
const Main = styled.main`
  height: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Body = styled.div`
  margin: 0;
  padding: 0;
  height: inherit;
`

export default Layout
