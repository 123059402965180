import React from 'react'
import styled from 'styled-components'

const SectionContainer = (props) => {
  const bgColor = props.style.backgroundColor;
  delete props.style.backgroundColor;

  return (
    <Container id={props.id} style={{ ...props.style }} {...props}>
      <Content bgColor={bgColor}>
        {props.children}
      </Content>
    </Container>
  )
}

const Container = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
`

const Content = styled.div`
  background: ${props => props.bgColor};
  width: inherit;
  max-width: 1320px;
`

Container.defaultProps = {
  bgColor: '#FFF',
}

SectionContainer.defaultProps = {
  style: {
    backgroundColor: '#FFF'
  }
}

export default SectionContainer